import React from 'react'
import { useLocation } from 'react-router-dom'
import { BreadCrumb } from 'primereact/breadcrumb'

export const Breadcrumbs = () => {
  const location = useLocation()
  const pathname = location.pathname.split('/').filter(x => x)

  const items = pathname.map((value, index) => {
    const to = `/${pathname.slice(0, index + 1).join('/')}`
    return { label: value === 'navdrawer' ? 'EHEA content management' : 'Home', url: to }
  })

  const home = { icon: 'pi pi-home', url: '/' }

  return <BreadCrumb model={items} home={home} />
}
