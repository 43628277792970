import { Card, CardActions, Link } from '@mui/material'

export const OverviewCard = () => {
  return (
    <div className="card-container">
      <Card title="OKAS tool management/Strapi" className="card-title">
        <CardActions>
          <Link href="https://taltech-ghg-app-backend.onrender.com/admin/" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>OKAS tool management</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="OKAS Render dashboard" className="card-title">
        <CardActions>
          <Link href="https://dashboard.render.com/" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>OKAS Render dashboard</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="OKAS templates" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/spreadsheets/d/1O9JjF-bPCEika5bWrIk4mtKLXd6gKSSY/edit?gid=98688656#gid=98688656" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>OKAS templates</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="OKAS admin documentation" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/document/d/1-E18h0reI6fIBbrsh9C1KQadoSTGeZJT11NWxKx5iEc/edit#heading=h.e1gafgelrw83" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>OKAS templates</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="EHEA API documentation" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/document/d/1jJDXfMo-lsqmaEjAKPxrQ8rQ5WrDngxmEwEXzniBBec/edit" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>EHEA API documentation</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="SARV templates" className="card-title">
        <CardActions>
          <Link href="https://drive.google.com/drive/folders/1BwoohXi7zOzeJ8jAC2IxCUbgnkbTH33l?usp=sharing" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>SARV templates</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="SARV translation" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/spreadsheets/d/1x77mnNe6NiSws0ahOsNijmeSavDGOVmMV_CDfWqLPFo/edit?gid=27547303#gid=27547303" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>SARV Translation</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="Google analytics" className="card-title">
        <CardActions>
          <Link href="https://analytics.google.com/analytics/web/#/p435740092/reports/intelligenthome" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>Google analytics</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="Taltech developer guide" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/document/d/1TFkYUIoDtS1c9zjFBRmLna0q8qCx5bTf/edit" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>Taltech developer guide</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="Postman collection" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/document/d/14pcIZM08_A8JwWZiJPNsxwKkGV1eezGkbLrbqpEWZXQ/edit" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>Developer Postman collection</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="okas account creation" className="card-title">
        <CardActions>
          <Link href="https://docs.google.com/forms/d/1iUEzBfn2yOKe11_VEJ6oihIlAHve5gXH_GKXmXXGK8E/edit" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>OKAS account creation form</h4>
          </Link>
        </CardActions>
      </Card>
      <Card title="Password generator" className="card-title">
        <CardActions>
          <Link href="https://www.avast.com/random-password-generator#pc" target="_blank" rel="noopener"
                sx={{ color: 'black', textDecoration: 'none', paddingLeft: '16px' }}>
            <h4>Password generator</h4>
          </Link>
        </CardActions>
      </Card>
    </div>
  )
}
